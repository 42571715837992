<template>
  <v-list-item rounded="lg" :title="props.item.deptName" :value="props.item.deptId">
    <template #prepend="{ isSelected }">
      <v-list-item-action start>
        <v-checkbox-btn color="primary" :model-value="isSelected" @click.stop="onClick(props.item)" />
      </v-list-item-action>
      <icon-set class="pt-1" :color="setActiveIcon(props.item.activeYn).color" icon="circle_filled_16" />
    </template>
  </v-list-item>
</template>

<script setup>
const props = defineProps({ item: Object, selection: Array, selectionDepartments: Array })

const setActiveIcon = (activeYn = false) => {
  return { color: activeYn ? '#4CAF50' : '#BDBDBD' }
}

const onClick = (item = {}) => {
  const { deptId, children } = item
  const { selection, selectionDepartments } = props
  const isSelected = selection.includes(deptId)

  if (isSelected) {
    removeItem(selection, deptId)
    removeItem(selectionDepartments, deptId, 'deptId')
    setChildrenCheck(selection, children, false)
  } else {
    selection.push(deptId)
    selectionDepartments.push(item)
    setChildrenCheck(selection, children, true)
  }
}

const setChildrenCheck = (array = [], children = [], parentCheck = false) => {
  children.forEach((child) => {
    const { deptId } = child
    const isSelected = array.includes(deptId)

    if (parentCheck && !isSelected) {
      array.push(deptId)
    } else if (!parentCheck && isSelected) {
      removeItem(array, deptId)
    }
  })
}

const removeItem = (array, value, key = null) => {
  const index = key ? array.findIndex((item) => item[key] === value) : array.indexOf(value)
  if (index !== -1) array.splice(index, 1)
}
</script>
