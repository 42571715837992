<template>
  <v-dialog v-model="dialogVisible" :close-on-back="false" max-width="300" persistent>
    <v-card style="padding: 30px" rounded="content">
      <v-card-text class="pa-0">{{ message }}</v-card-text>
      <v-card-actions class="align-end d-flex justify-center pa-0">
        <v-btn class="text-title-5" color="button-grey" rounded="lg" text="닫기" variant="flat" @click="onClose" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({ visible: Boolean, message: String })
const emits = defineEmits(['update:visible', 'confirm'])

const dialogVisible = ref(false)

watch(
  () => props.visible,
  (value) => {
    dialogVisible.value = value
  }
)

const onClose = () => {
  emits('update:visible', false)
  emits('confirm')
}
</script>
