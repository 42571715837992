<template>
  <div class="month-picker">
    <v-menu v-model="menu" :close-on-content-click="false" location="bottom center">
      <template #activator="{ props }">
        <v-text-field
          :id="component.id"
          v-model="inputDate"
          v-bind="props"
          class="mx-1 mb-1 text-font-color"
          autocomplete="off"
          base-color="#777777"
          bg-color="#ffffff"
          clearable
          color="primary"
          density="compact"
          :disabled="component.disabled"
          :hide-details="component.hideDetails"
          placeholder="YYYY-MM"
          :required="component.required"
          rounded="lg"
          :rules="component.required ? rules : []"
          variant="outlined"
          @keyup.enter="setDate"
        >
          <template #append-inner>
            <icon-set class="pt-2" icon="calendar_20" />
          </template>
          <template #clear>
            <icon-set class="pt-2" icon="delete_20" @click="onClearData" />
          </template>
          <template #label>
            <span>
              {{ component.label }}
              <span v-if="component.required" class="font-weight-bold text-error"> *</span>
            </span>
          </template>
          <template #message>
            <div v-if="component.required" class="text-right" style="margin: -4px -15px 4px 0">
              <span class="text-error text-body-4">! 필수 항목입니다.</span>
            </div>
          </template>
        </v-text-field>
      </template>
      <v-card rounded="content" width="262">
        <vue-date-picker
          v-model="month"
          class="month-picker primary"
          auto-apply
          format="yyyy-MM"
          inline
          locale="ko"
          month-picker
          select-text="선택"
          timezone="Asia/Tokyo"
          @update:model-value="onClick"
        >
          <template #arrow-left>
            <icon-set class="pt-1" icon="arrow_left_20" />
          </template>
          <template #arrow-right>
            <icon-set class="pt-1" icon="arrow_right_20" />
          </template>
        </vue-date-picker>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import dayjs from 'dayjs'

const emits = defineEmits(['set-date'])
const props = defineProps({ component: Object })

const date = ref(null)
const inputDate = ref(null)
const menu = ref(false)
const month = ref({ year: dayjs().year(), month: dayjs().month() })
const rules = [(v) => !!v || '필수 항목입니다.']

const setDate = () => {
  if (inputDate.value && inputDate.value.length > 6) {
    date.value = dayjs(inputDate.value).year()
    month.value = { year: dayjs(inputDate.value).year(), month: dayjs(inputDate.value).month() }
    emits('set-date', inputDate.value, props.component)
  } else {
    menu.value = true
  }
}

const onClick = (value) => {
  const month = `${value.year}-${value.month}`
  inputDate.value = dayjs(month).add(1, 'months').format('YYYY-MM')
  emits('set-date', inputDate.value, props.component)
}
const onClearData = () => {
  date.value = null
  inputDate.value = ''
  month.value = { year: dayjs().year(), month: dayjs().month() }
}
</script>
