const config = {
  sidebar_drawer: true,
  mini_sidebar: false,
  menu_histories: localStorage.getItem('customer-integration-menu-histories'),
  selected_history: null,
  loader: false,
  snackbar: {
    visible: false,
    message: '',
  },
}

export default config
