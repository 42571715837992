<template>
  <v-radio-group v-model="inputForm[component.id]" color="primary" density="compact" inline>
    <v-radio v-for="element in component.items" :key="element.value" :label="element.title" :value="element.value" />
    <template #label>
      {{ component.label }}
      <span v-if="component.required" class="font-weight-bold text-error"> *</span>
    </template>
  </v-radio-group>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({ component: Object, form: Object })

const inputForm = ref({})

watch(
  () => props.form,
  (value) => {
    inputForm.value = value
  },
  { deep: true }
)
</script>
