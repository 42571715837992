<template>
  <div class="month-range-picker">
    <v-menu v-model="menu" :close-on-content-click="false" location="bottom center">
      <template #activator="{ props }">
        <v-text-field
          :id="component.id"
          v-model="displayMonth"
          v-bind="props"
          class="mx-1 mb-1 text-font-color"
          autocomplete="off"
          base-color="#777777"
          bg-color="#ffffff"
          color="primary"
          density="compact"
          :disabled="component.disabled"
          :hide-details="component.hideDetails"
          placeholder="YYYY-MM ~ YYYY-MM"
          :required="component.required"
          rounded="lg"
          :rules="component.required ? rules : []"
          variant="outlined"
        >
          <template #append-inner>
            <icon-set class="pt-2" icon="calendar_20" />
          </template>
          <template #label>
            <span>
              {{ component.label }}
              <span v-if="component.required" class="font-weight-bold text-error"> *</span>
            </span>
          </template>
          <template #message>
            <div v-if="component.required" class="text-right" style="margin: -4px -15px 4px 0">
              <span class="text-error">! 필수 항목입니다.</span>
            </div>
          </template>
        </v-text-field>
      </template>
      <v-card rounded="content" width="262">
        <vue-date-picker
          v-model="monthRange"
          class="month-range-picker primary"
          auto-apply
          format="yyyy-MM"
          inline
          locale="ko"
          month-picker
          multiple="range"
          range
          timezone="Asia/Tokyo"
          @update:model-value="setMonthRange"
        >
          <template #arrow-left>
            <icon-set class="pt-1" icon="arrow_left_20" />
          </template>
          <template #arrow-right>
            <icon-set class="pt-1" icon="arrow_right_20" />
          </template>
        </vue-date-picker>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import dayjs from 'dayjs'

const emits = defineEmits(['set-range-date'])
const props = defineProps({ component: Object, form: Object })

const displayMonth = ref('')
const menu = ref(false)
const monthRange = ref([new Date(), new Date()])
const rules = [(v) => !!v || '필수 항목입니다.']
const sendDateRange = ref([])

watch(
  () => props.form,
  (value) => {
    if (value[props.component.ranges[0]] || value[props.component.ranges[1]]) {
      monthRange.value = [dayjs(value[props.component.ranges[0]]), dayjs(value[props.component.ranges[1]])]
      displayMonth.value = `${dayjs(value[props.component.ranges[0]]).format('YYYY-MM')} ~ ${dayjs(value[props.component.ranges[1]]).format('YYYY-MM')}`
    } else {
      monthRange.value = []
      displayMonth.value = ''
    }
  },
  { deep: true, immediate: true }
)

const setMonthRange = (value) => {
  if (value.length >= 2) {
    const startDate = dayjs().year(value[0].year).month(value[0].month).format('YYYY-MM')
    const endDate = dayjs()
      .year(value[value.length - 1].year)
      .month(value[value.length - 1].month)
      .format('YYYY-MM')

    monthRange.value = [value[0], value[value.length - 1]]
    displayMonth.value = `${startDate} ~ ${endDate}`
    sendDateRange.value = [startDate, endDate]
  } else if (value.length === 1) {
    const selectedDate = dayjs().year(value[0].year).month(value[0].month).format('YYYY-MM')
    displayMonth.value = `${selectedDate} ~ ${selectedDate}`
    sendDateRange.value = [selectedDate, selectedDate]
  } else {
    displayMonth.value = ''
    sendDateRange.value = []
  }

  emits('set-range-date', sendDateRange.value, props.component)
}
</script>
