<template>
  <v-dialog v-model="modalVisible" :close-on-back="false" max-width="1300" persistent>
    <v-card style="padding: 30px" rounded="content">
      <v-card-title class="align-top d-flex justify-space-between pa-0">
        <div class="ps-2 text-font-color text-title-3">사원 검색</div>
        <icon-set class="cursor-pointer pe-1" icon="close_20" @click="onClose" />
      </v-card-title>
      <v-card-text class="pa-0">
        <v-card class="mb-5" flat rounded="content">
          <search-box
            v-model:props-form="searchForm"
            :search-components="searchComponents"
            @search="onSearch"
            @initialize="onInitialize"
          />
        </v-card>
        <span class="text-font-color">※ 최대 10명의 인원이 표시됩니다.</span>
        <v-card class="mb-5" flat rounded="content">
          <v-data-table
            class="default-table pa-1"
            density="compact"
            :headers="headers"
            hide-default-body
            hide-default-footer
            :items="rowData"
          >
            <template #headers="{ columns }">
              <tr class="default-table-header">
                <td
                  v-for="column in columns"
                  :key="column.key"
                  class="table-header text-title-5"
                  :style="`width: ${column.width}`"
                >
                  {{ column.title }}
                </td>
              </tr>
            </template>
            <template #tbody="{ items }">
              <template v-if="items.length > 0">
                <tr v-for="(element, index) in items" :key="index" class="cursor-pointer" @click="onSelect(element)">
                  <td
                    v-for="column in headers"
                    :key="`${element.employeeNumber}-${column.key}`"
                    class="text-body-3 text-font-color"
                  >
                    {{ element[column.key] }}
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="5">조회된 정보가 없습니다.</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
    <alert-view v-model:visible="alert" message="FA코드 또는 FA명을 입력 후 검색하세요." />
  </v-dialog>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { searchComponents, headers } from './js'
import * as MEMBER from '@/api/assist-api/member'

const props = defineProps({ visible: Boolean })
const emits = defineEmits(['selectEmployee', 'update:visible'])
const store = useStore()

const modalVisible = ref(false)
const searchForm = ref({})
const rowData = ref([])
const alert = ref(false)

watch(
  () => props.visible,
  (value) => {
    modalVisible.value = value
  }
)

const onClose = () => {
  onInitialize()
  emits('update:visible', false)
}
const onSearch = () => {
  if (!searchForm.value.employeeNumber && !searchForm.value.nameBase) {
    alert.value = true
    return
  }
  store.dispatch('showLoader')
  MEMBER.getPinesMemberEmployees(searchForm.value).then((payload) => {
    rowData.value = payload.data.contents
    store.dispatch('hideLoader')
  })
}
const onInitialize = () => {
  searchForm.value = {}
  rowData.value = []
}
const onSelect = (item = {}) => {
  emits('selectEmployee', item)
  onClose()
}
</script>
