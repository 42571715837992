import { POST } from '@/plugins/axios'
import store from '@/store'
import axios from 'axios'
import { useCookies } from 'vue3-cookies'

const API_URLS = {
  PINES_AUTH: `${process.env.VUE_APP_PINES}/auth`,
  PINES_EMPLOYEE: process.env.VUE_APP_PINES_EMPLOYEE,
}

const COOKIE_KEYS = {
  AUTH_TOKEN: 'auth-token',
  AUTH_V2: 'Authorization-v2',
}

const { cookies } = useCookies()

/**
 * 인증 토큰을 기반으로 사용자 정보와 권한을 조회합니다.
 */
export const getAuth = async () => {
  try {
    const authToken = cookies.get(COOKIE_KEYS.AUTH_TOKEN)
    if (!authToken) return

    const authV2 = cookies.get(COOKIE_KEYS.AUTH_V2)
    await Promise.all([getPinesUser(authToken, authV2), getPinesAuthRoles(authToken, authV2)])
  } catch (error) {
    console.error('인증 정보 조회 실패:', error)
    throw error
  }
}

/**
 * Pines 사용자 정보를 조회합니다.
 */
const getPinesUser = async (authToken, authV2) => {
  try {
    const response = await axios.get(`${API_URLS.PINES_AUTH}/user`, {
      headers: getAuthHeaders(authToken, authV2),
    })
    store.commit('setUser', response.data.contents)
    return response
  } catch (error) {
    console.error('사용자 정보 조회 실패:', error)
    throw error
  }
}

/**
 * Pines 사용자 권한 정보를 조회합니다.
 */
const getPinesAuthRoles = async (authToken, authV2) => {
  try {
    const response = await axios.get(`${API_URLS.PINES_AUTH}/roles`, {
      headers: getAuthHeaders(authToken, authV2),
    })
    store.commit('setAuthRoles', response.data.contents)
    return response
  } catch (error) {
    console.error('권한 정보 조회 실패:', error)
    throw error
  }
}

/**
 * Pines 로그인을 수행합니다.
 */
export const postPinesAuthSignIn = async (request = {}) => {
  try {
    return await POST(`${API_URLS.PINES_EMPLOYEE}/auth/sign-in`, request)
  } catch (error) {
    console.error('로그인 실패:', error)
    throw error
  }
}

/**
 * 비밀번호 변경 SMS를 요청합니다.
 */
export const postAuthChangePasswordSms = async (request = {}) => {
  try {
    return await POST(`${API_URLS.PINES_EMPLOYEE}/auth/password/sms`, request)
  } catch (error) {
    console.error('비밀번호 변경 SMS 요청 실패:', error)
    throw error
  }
}

/**
 * 직원 프로필 이미지 URL을 반환합니다.
 */
export const getEmployeeImageProfile = (employeeNumber) => {
  return `${API_URLS.PINES_EMPLOYEE}/employee/image/profile/${employeeNumber}`
}

/**
 * 인증 헤더를 생성합니다.
 */
const getAuthHeaders = (authToken, authV2) => ({
  Authorization: authToken,
  'Authorization-v2': authV2,
})
