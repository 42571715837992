import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'

import { getAuth } from './api/auth'
import { getEnumType } from './api/customer-service/enum'
import * as AUTH_INFO from '@/plugins/auth'
import { handleInput } from './utils/format'
import * as dayjs from 'dayjs'
import * as isLeapYear from 'dayjs/plugin/isLeapYear'
import './scss/style.scss'

import 'dayjs/locale/ko'
import 'vue3-perfect-scrollbar/style.css'
import '@vuepic/vue-datepicker/dist/main.css'

import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar'
import VueDatePicker from '@vuepic/vue-datepicker'
import SearchBox from './components/search-box/SearchBox.vue'
import DefaultInputForm from './components/input-form/DefaultInputForm.vue'
import ContentToolbar from './components/content-toolbar/ContentToolbar.vue'
import DefaultPagination from './components/pagination/DefaultPagination.vue'
import DepartmentTree from './components/department-tree/DepartmentTree.vue'
import IconSet from './components/icon/IconSet.vue'
import SearchEmployeeDialog from './components/search-employee/SearchEmployeeDialog.vue'
import AlertView from './components/alert/AlertView.vue'
import ConfirmDialog from './components/confirm/dialog/ConfirmDialog.vue'

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

AUTH_INFO.setExpiresSession()

dayjs.extend(isLeapYear)
dayjs.locale('ko')

const app = createApp(App)

async function init() {
  await getAuth()
  await getEnumType()

  app.component('SearchBox', SearchBox)
  app.component('DefaultInputForm', DefaultInputForm)
  app.component('ContentToolbar', ContentToolbar)
  app.component('DefaultPagination', DefaultPagination)
  app.component('VueDatePicker', VueDatePicker)
  app.component('DepartmentTree', DepartmentTree)
  app.component('IconSet', IconSet)
  app.component('SearchEmployeeDialog', SearchEmployeeDialog)
  app.component('QuillEditor', QuillEditor)
  app.component('AlertView', AlertView)
  app.component('ConfirmDialog', ConfirmDialog)

  app.config.globalProperties.$searchInitialize = (value = []) => {
    let searchForm = {}
    value.forEach((element) => {
      element.forEach((children) => {
        if (children.type !== 'dateRange') {
          searchForm[children.id] = children.defaultValue
        } else {
          if (children.defaultValue.length > 0) {
            searchForm[children.ranges[0]] = dayjs(children.defaultValue[0]).format('YYYY-MM-DD')
            searchForm[children.ranges[1]] = dayjs(children.defaultValue[1]).format('YYYY-MM-DD')
          } else {
            searchForm[children.ranges[0]] = ''
            searchForm[children.ranges[1]] = ''
          }
        }
      })
    })
    return searchForm
  }

  app.use(vuetify).use(PerfectScrollbarPlugin).use(store).use(router).mount('#app')

  app.config.globalProperties.$handleInput = (formatter, value) => {
    return handleInput(formatter, value)
  }
}

init()
