<template>
  <v-dialog v-model="modalVisible" :close-on-back="false" persistent max-width="300">
    <v-card style="padding: 30px" rounded="content">
      <v-card-title class="align-top d-flex justify-space-between pa-0 mb-3">
        <div class="text-title-3 text-font-color">{{ dialogContent }}</div>
        <icon-set class="pe-1 cursor-pointer" icon="close_20" @click="onClose" />
      </v-card-title>
      <v-card-text class="pa-0">{{ `입력하신 내용을 ${dialogContent}하시겠습니까?` }}</v-card-text>
      <v-card-actions class="align-end d-flex justify-center pa-0">
        <v-btn class="text-title-5" color="primary" rounded="lg" text="확인" variant="flat" @click="onConfirm" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

const emits = defineEmits(['update:visible', 'confirm'])
const props = defineProps({ visible: Boolean, type: String })

const modalVisible = ref(false)
const dialogContent = computed(() => {
  return props.type === 'save' ? '저장' : '초기화'
})

watch(
  () => props.visible,
  (value) => {
    modalVisible.value = value
  }
)

const onClose = () => {
  emits('update:visible', false)
}
const onConfirm = () => {
  emits('confirm', props.type)
  onClose()
}
</script>
