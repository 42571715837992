// Constants
const PHONE_FORMATS = {
  MOBILE: {
    MAX_LENGTH: 13,
    PATTERN: /^(\d{0,3})(\d{0,4})(\d{0,4})$/,
  },
  SEOUL: {
    MAX_LENGTH: 12,
    PATTERN: /^(\d{0,2})(\d{0,4})(\d{0,4})$/,
  },
  OTHER: {
    MAX_LENGTH: 13,
    PATTERN: /^(\d{0,3})(\d{0,4})(\d{0,4})$/,
  },
}

const COMMON_REGEX = {
  REMOVE_NON_DIGITS: /\D/g,
  REMOVE_TRAILING_DASH: /(-{1,2})$/g,
}

// Utility functions
const removeNonDigits = (value) => value.replace(COMMON_REGEX.REMOVE_NON_DIGITS, '')

const formatWithPattern = (value, pattern) => {
  return value
    .replace(COMMON_REGEX.REMOVE_NON_DIGITS, '')
    .replace(pattern, '$1-$2-$3')
    .replace(COMMON_REGEX.REMOVE_TRAILING_DASH, '')
}

const truncateAndFormat = (value, maxLength, pattern) => {
  let temp = removeNonDigits(value)
  if (temp.length >= maxLength - 2) {
    temp = temp.slice(0, maxLength - 2)
  }
  return formatWithPattern(temp, pattern)
}

// Formatter functions
export const handleInput = (formatter, value) => {
  const formatters = {
    phoneNumber: formatPhoneNumber,
    businessNumber: formatBusinessNumber,
    number: formatNumber,
    date: formatDate,
    birthday: formatBirthday,
    dateRange: formatDateRange,
  }

  return formatters[formatter]?.(value) ?? value
}

export const formatPhoneNumber = (value) => {
  if (!value) return ''

  if (value.startsWith('01') || value.startsWith('07')) {
    return value.length < PHONE_FORMATS.MOBILE.MAX_LENGTH
      ? formatWithPattern(value, PHONE_FORMATS.MOBILE.PATTERN)
      : truncateAndFormat(value, PHONE_FORMATS.MOBILE.MAX_LENGTH, PHONE_FORMATS.MOBILE.PATTERN)
  }

  if (value.startsWith('02')) {
    return value.length < PHONE_FORMATS.SEOUL.MAX_LENGTH
      ? formatWithPattern(value, PHONE_FORMATS.SEOUL.PATTERN)
      : truncateAndFormat(value, PHONE_FORMATS.SEOUL.MAX_LENGTH, PHONE_FORMATS.SEOUL.PATTERN)
  }

  return value.length < PHONE_FORMATS.OTHER.MAX_LENGTH
    ? formatWithPattern(value, PHONE_FORMATS.OTHER.PATTERN)
    : truncateAndFormat(value, PHONE_FORMATS.OTHER.MAX_LENGTH, PHONE_FORMATS.OTHER.PATTERN)
}

const formatBusinessNumber = (value) => {
  if (!value) return ''

  const digits = removeNonDigits(value)
  if (digits === '' || isNaN(digits)) return ''

  const limited = digits.slice(0, 10)

  if (limited.length > 5) {
    return `${limited.slice(0, 3)}-${limited.slice(3, 5)}-${limited.slice(5)}`
  }
  if (limited.length > 3) {
    return `${limited.slice(0, 3)}-${limited.slice(3)}`
  }
  return limited
}

const formatNumber = (value) => {
  if (value !== 0 && !value) return 0
  const cleanValue = String(value).replaceAll(',', '')
  return cleanValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const formatDate = (value) => {
  if (!value) return ''

  const digits = removeNonDigits(value)
  if (digits === '' || isNaN(digits)) return ''

  const limited = digits.slice(0, 10)

  if (limited.length > 7) {
    return `${limited.slice(0, 4)}-${limited.slice(4, 6)}-${limited.slice(6)}`
  }
  if (limited.length > 4) {
    return `${limited.slice(0, 4)}-${limited.slice(4)}`
  }
  return limited
}

const formatBirthday = (value) => {
  if (!value) return ''

  const digits = removeNonDigits(value)
  if (digits === '' || isNaN(digits)) return ''

  const limited = digits.slice(0, 7)

  if (limited.length > 6) {
    return `${limited.slice(0, 6)}-${limited.slice(6)}`
  }
  return limited
}

const formatDateRange = (value) => {
  if (!value) return ''

  const digits = removeNonDigits(value)
  if (digits === '' || isNaN(digits)) return ''

  const limited = digits.slice(0, 16)

  if (limited.length > 14) {
    return `${limited.slice(0, 4)}-${limited.slice(4, 6)}-${limited.slice(6, 8)}~${limited.slice(8, 12)}-${limited.slice(12, 14)}-${limited.slice(14)}`
  }
  if (limited.length > 12) {
    return `${limited.slice(0, 4)}-${limited.slice(4, 6)}-${limited.slice(6, 8)}~${limited.slice(8, 12)}-${limited.slice(12)}`
  }
  if (limited.length > 8) {
    return `${limited.slice(0, 4)}-${limited.slice(4, 6)}-${limited.slice(6, 8)}~${limited.slice(8)}`
  }
  if (limited.length > 7) {
    return `${limited.slice(0, 4)}-${limited.slice(4, 6)}-${limited.slice(6)}`
  }
  if (limited.length > 4) {
    return `${limited.slice(0, 4)}-${limited.slice(4)}`
  }
  return limited
}
