<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <v-list-group class="px-3">
    <template #activator="{ props }">
      <tree-item v-bind="props" :item="item" :selection="selection" :selection-departments="selectionDepartments" />
    </template>
    <template v-for="subItem in item.children" :key="subItem.deptId">
      <tree-collapse
        v-if="subItem.children && subItem.children.length > 0"
        :item="subItem"
        :level="props.level + 1"
        :selection="selection"
        :selection-departments="selectionDepartments"
      />
      <tree-item v-else :item="subItem" :selection="selection" :selection-departments="selectionDepartments" />
    </template>
  </v-list-group>
</template>

<script setup>
import TreeItem from '../tree-item/TreeItem.vue'

const props = defineProps({ item: Object, level: Number, selection: Array, selectionDepartments: Array })
</script>
