<template>
  <span v-html="icon" />
</template>

<script setup>
import { useRoute } from 'vue-router'
import { ref, watch } from 'vue'
import * as SET_ICON from './dataIndex'

const props = defineProps({
  color: String,
  filled: { type: Boolean, default: false },
  icon: String,
  name: String,
  opacity: Number,
  subColor: { type: String, default: '#ffffff' },
  type: String,
})
const route = useRoute()

const icon = ref(null)

const setIcon = () => {
  const selectedFlag = route.matched[1].name === props.name
  if (props.type === 'main-menu') icon.value = SET_ICON.setParentSidebarIcon(props.icon, selectedFlag)
  else icon.value = SET_ICON.setIcon(props.icon, props.color, props.subColor, props.opacity)
}

watch(
  () => route,
  () => {
    setIcon()
  },
  { deep: true, immediate: true }
)
watch(
  () => props.icon,
  () => {
    setIcon()
  }
)
watch(
  () => props.color,
  () => {
    setIcon()
  }
)
</script>
