<template>
  <div class="year-picker">
    <v-menu v-model="menu" :close-on-content-click="false" location="bottom center">
      <template #activator="{ props }">
        <v-text-field
          :id="component.id"
          v-model="inputDate"
          v-bind="props"
          class="mx-1 mb-1 text-font-color"
          autocomplete="off"
          base-color="#777777"
          bg-color="#ffffff"
          color="primary"
          density="compact"
          :disabled="component.disabled"
          :hide-details="component.hideDetails"
          placeholder="YYYY"
          :required="component.required"
          rounded="lg"
          :rules="component.required ? rules : []"
          variant="outlined"
        >
          <template #append-inner>
            <icon-set class="pt-2" icon="calendar_20" />
          </template>
          <template #clear>
            <icon-set class="pt-2" icon="delete_20" @click="onClearData" />
          </template>
          <template #label>
            <span>
              {{ component.label }}
              <span v-if="component.required" class="font-weight-bold text-error"> *</span>
            </span>
          </template>
          <template #message>
            <div v-if="component.required" class="text-right" style="margin: -4px -15px 4px 0">
              <span class="text-error text-body-4">! 필수 항목입니다.</span>
            </div>
          </template>
        </v-text-field>
      </template>
      <v-card rounded="content" width="262">
        <vue-date-picker
          v-model="year"
          class="year-picker primary"
          auto-apply
          format="yyyy"
          inline
          locale="ko"
          timezone="Asia/Tokyo"
          year-picker
          @update:model-value="onClick"
        >
          <template #arrow-left>
            <icon-set class="pt-1" icon="arrow_left_20" />
          </template>
          <template #arrow-right>
            <icon-set class="pt-1" icon="arrow_right_20" />
          </template>
        </vue-date-picker>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import dayjs from 'dayjs'

const emits = defineEmits(['set-date'])
const props = defineProps({ component: Object })

const inputDate = ref(null)
const menu = ref(false)
const rules = [(v) => !!v || '필수 항목입니다.']
const year = ref(dayjs().year())

watch(inputDate, (value) => {
  if (value && value.length === 4) {
    year.value = dayjs(value).year()
    emits('set-date', value, props.component)
  }
})

const onClick = (value) => {
  inputDate.value = `${value}`
  emits('set-date', inputDate.value, props.component)
}
const onClearData = () => {
  inputDate.value = null
  year.value = dayjs().year()
}
</script>
