<template>
  <v-textarea
    :id="component.id"
    v-model="inputForm[component.id]"
    class="mx-1 mb-1 text-font-color"
    autocomplete="off"
    base-color="#777"
    clearable
    color="primary"
    density="compact"
    :disabled="component.disabled"
    :hide-details="component.hideDetails"
    :readonly="component.readonly"
    rounded="lg"
    row-height="25"
    rows="3"
    :rules="component.required ? rules : []"
    variant="outlined"
  >
    <template #clear>
      <icon-set v-if="!component.disabled && !component.readonly" class="pt-2" icon="delete_20" @click="onClearData" />
    </template>
    <template #label>
      {{ component.label }}
      <span v-if="component.required" class="font-weight-bold text-error"> *</span>
    </template>
    <template #message>
      <div v-if="component.required" class="text-right" style="margin: -4px -15px 4px 0">
        <span class="text-error text-body-4">! 필수 항목입니다.</span>
      </div>
    </template>
  </v-textarea>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'

const props = defineProps({ component: Object, form: Object })

const inputForm = ref({})
const rules = [(v) => !!v || '필수 항목입니다.']

onMounted(() => {
  inputForm.value = props.form
})

watch(
  () => props.form,
  (value) => {
    inputForm.value = value
  },
  { deep: true }
)

const onClearData = () => {
  inputForm.value[props.component.id] = props.component.defaultValue
}
</script>
