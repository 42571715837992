import axios from 'axios'
import store from '@/store'
import pinesAuth from '@/plugins/auth'

const instance = axios.create({
  headers: {
    common: {
      Accept: '*/*',
    },
  },
})

instance.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = pinesAuth.getAuthToken1
    config.headers['Authorization-v2'] = pinesAuth.getAuthToken2

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default instance

const axiosInstance = axios.create({
  headers: {
    common: {
      Accept: '*/*',
    },
  },
})

const onRequest = (config) => {
  config.headers['Authorization'] = pinesAuth.getAuthToken1
  config.headers['Authorization-v2'] = pinesAuth.getAuthToken2

  return config
}

const handleHttpError = (status) => {
  switch (status) {
    case 404:
      return '요청한 페이지를 찾을 수 없습니다.'
    case 405:
      return '잘못된 요청입니다.'
    case 500:
      return '서버 내부 오류가 발생했습니다.'
    default:
      return '알 수 없는 오류가 발생했습니다.'
  }
}

const onResponse = (response) => {
  return response
}

const onError = (error) => {
  const status = error.response?.status
  if (status) {
    const errorMessage = handleHttpError(status)
    store.dispatch('showSnackbar', { message: errorMessage })
  }
  return Promise.reject(error)
}

axiosInstance.interceptors.request.use(onRequest, onError)
axiosInstance.interceptors.response.use(onResponse, onError)

const handleResponse = (promise) => promise.then((payload) => payload).catch((error) => error.response)

export const GET = async (url, params) => handleResponse(axiosInstance.get(url, { params }))
export const POST = async (url, data) => handleResponse(axiosInstance.post(url, data))
export const PUT = async (url, data) => handleResponse(axiosInstance.put(url, data))
export const DELETE = async (url) => handleResponse(axiosInstance.delete(url))
export const UPLOAD = async (url, data) =>
  handleResponse(axiosInstance.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' } }))
