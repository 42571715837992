<template>
  <div>
    <v-card class="pt-5" :class="{ 'px-4': type === 'main' }" flat rounded="content">
      <v-form :id="id" ref="validForm" @submit.prevent>
        <v-row no-gutters>
          <v-col
            v-for="(component, index) in components"
            :key="`${component.id}-${index}`"
            :class="component.type === 'button' ? 'text-right' : ''"
            cols="12"
            :sm="component.sm"
          >
            <component
              v-if="isBasicInputType(component.type)"
              :is="getInputComponent(component.type)"
              :form="form"
              :component="component"
              @update:form="handleFormUpdate"
              @keyup.enter="keyupEvent"
            />
            <component
              v-else-if="isDatePickerType(component.type)"
              v-show="componentVisible"
              :is="getDatePickerComponent(component.type)"
              :form="form"
              :component="component"
              @update:form="handleFormUpdate"
              @set-date="setDate"
              @set-range-date="setRangeDate"
              @set-register-number="setRegisterNumber"
            />
            <slot v-if="component.type === 'custom'" :id="component.id" :item="component" />
            <department-tree
              v-if="component.type === 'departmentTree'"
              :id="component.id"
              :active-yn="component.activeYn ? component.activeYn : ''"
              :component="component"
              :default-value="component.defaultValue ? component.defaultValue : []"
              :max-level="component.maxLevel ? component.maxLevel : 3"
              :search-type="component.searchType ? component.searchType : ''"
              @selection="setSelectionDepartment"
            />
            <default-radio v-if="component.type === 'radio'" :component="component" />
            <contract-concept v-if="component.type === 'contractConcept'" v-model:form="form" :component="component" />
            <default-switch v-if="component.type === 'switch'" v-model:form="form" :component="component" />
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-row v-if="visibleBtn" class="text-right">
      <v-col class="mt-5">
        <v-btn
          class="text-title-5"
          color="error"
          rounded="lg"
          text="저장"
          variant="flat"
          width="66px"
          @click="onSave"
        />
        <v-btn
          v-if="initialize"
          class="ml-2 text-title-5"
          color="#666666"
          rounded="lg"
          text="초기화"
          variant="flat"
          @click="onInitialize"
        >
          <template #append>
            <icon-set class="pt-1" color="#ffffff" icon="refresh_20" />
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <confirm-dialog v-model:visible="modal.visible" :type="modal.type" @confirm="onConfirm" />
    <alert-view v-model:visible="alert.visible" :message="alert.message" />
  </div>
</template>

<script setup>
import { watch, onMounted, ref, computed } from 'vue'
import DefaultInput from './components/input/DefaultInput.vue'
import DefaultEmailInput from './components/input/DefaultEmailInput.vue'
import DefaultNumberInput from './components/input/DefaultNumberInput.vue'
import DefaultSelect from './components/select/DefaultSelect.vue'
import DefaultTextarea from './components/input/DefaultTextarea.vue'
import DatePicker from '../date-picker/DatePicker.vue'
import DateRangePicker from '../date-picker/DateRangePicker.vue'
import MonthPicker from '../date-picker/MonthPicker.vue'
import MonthRangePicker from '../date-picker/MonthRangePicker.vue'
import YearPicker from '../date-picker/YearPicker.vue'
import RegisterPicker from '../date-picker/RegisterPicker.vue'
import ConfirmDialog from './components/ConfirmDialog.vue'
import DefaultRadio from './components/radio/DefaultRadio.vue'
import ContractConcept from '../contract-concept/ContractConcept.vue'
import DefaultSwitch from './components/radio/DefaultSwitch.vue'
import InsuranceAgePicker from '../date-picker/InsuranceAgePicker.vue'

const emits = defineEmits(['update:checkValid', 'submit', 'initialize', 'alert', 'keyup-event'])
const props = defineProps({
  id: { type: String, default: 'defaultInputForm' },
  components: Array,
  propsForm: Object,
  visibleBtn: { type: Boolean, default: true },
  checkValid: Boolean,
  initialize: { type: Boolean, default: true },
  type: { type: String, default: 'main' },
  alertType: Boolean,
})

const componentVisible = ref(false)
const form = ref({})
const modal = ref({ visible: false, type: null })
const alert = ref({ visible: false, message: '' })
const validForm = ref()

const basicInputComponents = computed(() => ({
  input: DefaultInput,
  email: DefaultEmailInput,
  number: DefaultNumberInput,
  select: DefaultSelect,
  textarea: DefaultTextarea,
}))

const datePickerComponents = computed(() => ({
  date: DatePicker,
  dateRange: DateRangePicker,
  month: MonthPicker,
  monthRange: MonthRangePicker,
  year: YearPicker,
  register: RegisterPicker,
  insuranceAge: InsuranceAgePicker,
}))

const isBasicInputType = (type) => Object.keys(basicInputComponents.value).includes(type)
const isDatePickerType = (type) => Object.keys(datePickerComponents.value).includes(type)

const getInputComponent = (type) => basicInputComponents.value[type]
const getDatePickerComponent = (type) => datePickerComponents.value[type]

const handleFormUpdate = (value) => {
  form.value = { ...form.value, ...value }
}

onMounted(() => {
  componentVisible.value = true
  form.value = props.propsForm
})

watch(
  () => props.checkValid,
  async (value) => {
    if (value) validate()
  }
)

watch(
  () => props.propsForm,
  async (value) => {
    form.value = value
  },
  { deep: true }
)

const validate = async () => {
  const { valid } = await validForm.value.validate()

  emits('update:checkValid', false)
  if (valid) {
    let checkPhoneNumberResult = Object.keys(form.value).includes('phoneNumber')
      ? checkPhoneNumber(form.value['phoneNumber'], 'phoneNumber')
      : checkPhoneNumber(form.value['officeTelNumber'], 'officeTelNumber')
    let checkBusinessNumberResult = Object.keys(form.value).includes('businessNumber')
      ? checkBusinessNumber(form.value['businessNumber'])
      : { success: true }
    if (checkPhoneNumberResult.success && checkBusinessNumberResult.success) {
      emits('submit')
    } else if (!checkPhoneNumberResult.success) {
      showAlert(checkPhoneNumberResult.message)
    } else if (!checkBusinessNumberResult.success) {
      showAlert(checkBusinessNumberResult.message)
    }
  } else {
    if (props.alertType) {
      emits('alert')
    } else {
      showAlert('필수 항목 입력 누락입니다.')
    }
  }
}
const onSave = () => {
  showConfirmDialog('save')
}
const onInitialize = () => {
  onInitializeConfirm()
}

const showConfirmDialog = (type) => {
  modal.value = {
    visible: true,
    type,
  }
}

const onConfirm = (type) => {
  if (type === 'save') validate()
  else onInitializeConfirm()
}
const onInitializeConfirm = () => {
  componentVisible.value = false
  validForm.value.reset()
  emits('initialize')
  setTimeout(() => {
    componentVisible.value = true
  }, 10)
}

const setDate = (value, component) => {
  form.value[component.id] = value
}
const setSelectionDepartment = (value, component) => {
  form.value[component.id] = value
}
const setRangeDate = (value, component) => {
  if (component.ranges.length >= 2) {
    form.value[component.ranges[0]] = value[0]
    form.value[component.ranges[1]] = value[1]
  }
}
const setRegisterNumber = (value, component) => {
  if (component.dataSet?.length === 2) {
    form.value[component.dataSet[0]] = value[0]
    form.value[component.dataSet[1]] = value[1]
  } else {
    form.value[component.id] = value[0]
    form.value[`${component.id}2`] = value[1]
  }
}
const checkPhoneNumber = (value, type) => {
  if (!value) return { success: true }

  const splitNum = value.split('-')
  const [prefix, middle, last] = splitNum

  const validations = [
    {
      condition: (type === 'phoneNumber' && value.length < 13) || (type !== 'phoneNumber' && value.length < 12),
      message: '전화번호를 모두 입력하세요.',
    },
    {
      condition: type === 'phoneNumber' && prefix !== '010',
      message: '휴대폰 번호는 010부터 입력하세요.',
    },
    {
      condition: checkConsecutivePhoneNumbers(middle) && checkConsecutivePhoneNumbers(last),
      message: '휴대폰 번호 확인 후 입력 바랍니다.',
    },
    {
      condition: middle === last,
      message: '핸드폰 앞번호와 뒷번호가 일치합니다.',
    },
    {
      condition: middle === '1234' || last === '1234',
      message: '핸드폰 번호는 1234로 시작할 수 없습니다.',
    },
    {
      condition: middle[0] === '0',
      message: '핸드폰 번호가 0으로 시작됩니다.',
    },
    {
      condition: middle % 1000 === 0 && last % 1000 === 0,
      message: '입력 불가능한 번호가 입력되었습니다.',
    },
  ]

  const failedValidation = validations.find((validation) => validation.condition)
  return failedValidation ? { success: false, message: failedValidation.message } : { success: true }
}
const checkBusinessNumber = (value) => {
  if (value.length < 12) {
    return { success: false, message: '사업자 등록번호를 확인해주세요.' }
  } else {
    return { success: true }
  }
}
const checkConsecutivePhoneNumbers = (str = '') => {
  return /([0-9a-zA-Z])\1{3,}/.test(str)
}
const showAlert = (message) => {
  alert.value = {
    visible: true,
    message,
  }
}
const keyupEvent = () => {
  emits('keyup-event')
}
</script>

<style scoped>
input::-webkit-calendar-picker-indicator {
  opacity: 0;
}
</style>
