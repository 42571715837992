<template>
  <v-text-field
    :id="component.id"
    v-model="inputForm[component.id]"
    class="mx-1 mb-1 text-font-color"
    autocomplete="off"
    base-color="#777777"
    clearable
    color="primary"
    density="compact"
    :disabled="component.disabled"
    :hide-details="component.hideDetails"
    :hint="component.hint"
    :placeholder="component.placeholder"
    :readonly="component.readonly"
    rounded="lg"
    :rules="component.required ? rules : []"
    :required="component.required"
    variant="outlined"
    @input="inputForm[component.id] = $handleInput(component.formatter, $event.target.value)"
  >
    <template #clear>
      <icon-set v-if="!component.disabled && !component.readonly" class="pt-2" icon="delete_20" @click="onClearData" />
    </template>
    <template #label>
      {{ component.label }}
      <span v-if="component.required" class="font-weight-bold text-error"> *</span>
    </template>
    <template #message>
      <div v-if="component.required" class="text-right" style="margin: -4px -15px 4px 0">
        <span class="text-body-4 text-error">! 필수 항목입니다.</span>
      </div>
      <span v-else-if="component.hint">{{ component.hint }}</span>
    </template>
  </v-text-field>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'

const props = defineProps({ component: Object, form: Object })
const rules = [(v) => !!v || '필수 항목입니다.']
const inputForm = ref({})

onMounted(() => {
  inputForm.value = props.form
})

watch(
  () => props.form,
  (value) => {
    inputForm.value = value
    if (props.component.formatter === 'number')
      inputForm.value[props.component.id] = formatNumber(inputForm.value[props.component.id])
  },
  { deep: true }
)

const onClearData = () => {
  inputForm.value[props.component.id] = props.component.defaultValue
}
const formatNumber = (value) => {
  if (!value || value === 0) return 0
  else {
    if (value.startsWith('0')) value = value.substr(1)
    value = value.replaceAll(',', '')
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}
</script>
