import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { ko } from 'vuetify/locale'
import * as labsComponents from 'vuetify/labs/components'

const vuetify = createVuetify({
  components: {
    ...components,
    ...labsComponents,
  },
  directives,
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#FF6600',
          'primary-2': '#FF8F4C',
          'primary-3': '#FFAF7F',
          secondary: '#FBB03F',
          'secondary-1': '#FBB03F',
          'secondary-2': '#9E7E6C',
          'secondary-3': '#DB3A3A',
          'secondary-4': '#A75788',
          'secondary-5': '#323030',
          warning: '#FF6600',
          success: '#0150AD',
          error: '#D80909',
          'button-black': '#444444',
          'button-grey': '#666666',
          'font-color': '#333333',
        },
        variables: {
          'activated-opacity': 0,
        },
      },
    },
  },
  locale: {
    locale: 'ko',
    messages: { ko },
  },
})

export default vuetify
