export const searchComponents = [
  [
    { id: 'employeeNumber', type: 'input', label: 'FA코드', defaultValue: null },
    { id: 'nameBase', type: 'input', label: 'FA명', defaultValue: null },
    { id: 'deptIds', type: 'departmentTree', label: '부서', defaultValue: null, activeYn: 'Y', hideDetails: true },
    {
      id: 'state',
      type: 'select',
      label: '재직상태',
      defaultValue: '',
      items: [
        { value: '', title: '전체' },
        { value: 'S00001', title: '재직' },
        { value: 'S00002', title: '위촉' },
        { value: 'S00003', title: '해촉' },
        { value: 'S00004', title: '퇴직' },
        { value: 'S00007', title: '휴직' },
      ],
    },
  ],
]

export const headers = [
  { title: 'FA코드', key: 'employeeNumber', width: '12.5%' },
  { title: 'FA명', key: 'nameBase', width: '12.5%' },
  { title: '직급', key: 'titleStr', width: '12.5%' },
  { title: '재직상태', key: 'stateStr', width: '12.5%' },
  { title: '부서', key: 'newDeptPathName', width: '50%' },
]
