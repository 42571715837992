<template>
  <div class="default-email-input">
    <v-text-field
      :id="component.id"
      v-model="inputForm[component.id]"
      class="mx-1 mb-1 text-font-color"
      autocomplete="off"
      base-color="#777"
      clearable
      color="primary"
      density="compact"
      :disabled="component.disabled"
      :hide-details="component.hideDetails"
      :list="`${component.id}-emails`"
      rounded="lg"
      :rules="component.required ? rules : []"
      :required="component.required"
      variant="outlined"
      @update:model-value="onChangeEmail"
    >
      <template #clear>
        <icon-set
          v-if="!component.disabled && !component.readonly"
          class="pt-2"
          icon="delete_20"
          @click="onClearData"
        />
      </template>
      <template #label>
        {{ component.label }}
        <span v-if="component.required" class="font-weight-bold text-error"> *</span>
      </template>
      <template #message>
        <div v-if="component.required" class="text-right" style="margin: -4px -15px 4px 0">
          <span class="text-body-4 text-error">! 필수 항목입니다.</span>
        </div>
      </template>
    </v-text-field>
    <datalist :id="`${component.id}-emails`">
      <option v-for="element in frequencyEmails" :key="element" :value="element" />
    </datalist>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'

const props = defineProps({ component: Object, form: Object })

const rules = [(v) => !!v || '필수 항목입니다.']
const frequencyEmails = ref(['@daum.net', '@gmail.com', '@hanmail.net', '@nate.com', '@naver.com'])
const inputForm = ref({})

onMounted(() => {
  inputForm.value = props.form
})

watch(
  () => props.form,
  (value) => {
    inputForm.value = value
  },
  { deep: true }
)

const onChangeEmail = (value) => {
  if (!value) value = ''
  const userEmails = frequencyEmails.value.map((email) => {
    email = `@${email.split('@')[1]}`
    return value.includes('@') ? `${value.split('@')[0]}${email}` : `${value}${email}`
  })
  frequencyEmails.value = userEmails
}
const onClearData = () => {
  inputForm.value[props.component.id] = props.component.defaultValue
  onChangeEmail('')
}
</script>
