import { GET } from '@/plugins/axios'

const baseUrl = process.env.VUE_APP_COMMON
const pinesUrl = process.env.VUE_APP_PINES

export const getDepartments = async (params = {}) => {
  return GET(`${pinesUrl}/settings/depts`, params)
}

export const getInsurances = async () => {
  return GET(`${baseUrl}/insure`)
}

export const getPinesCommonCode = async (parentCodeId = '') => {
  return GET(`${baseUrl}/pines/common/code/${parentCodeId}`)
}

export const getProductTypes = async () => {
  return GET(`${baseUrl}/product-gubun`)
}

export const getProductTypesTree = async () => {
  return GET(`${baseUrl}/product-gubun/tree`)
}

export const getPinesHolydays = async (params = {}) => {
  return GET(`${pinesUrl}/pines/commons/pines-holiday/lists`, params)
}
