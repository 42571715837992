<template>
  <router-view @click="eventCheckExpiresSession" />
</template>

<script>
import * as AUTH_INFO from '@/plugins/auth'
export default {
  name: 'App',
  methods: {
    eventCheckExpiresSession() {
      if (AUTH_INFO.getExpiresSession()) AUTH_INFO.setExpiresSession()
      else AUTH_INFO.emptyAuth('EXPIRES')
    },
  },
}
</script>
