<template>
  <div class="date-picker">
    <v-menu v-model="menu" :close-on-content-click="false" location="bottom center">
      <template #activator="{ props }">
        <v-text-field
          :id="component.id"
          v-model="inputDate"
          v-bind="props"
          class="mx-1 mb-1 text-font-color"
          autocomplete="off"
          bg-color="#ffffff"
          base-color="#777777"
          clearable
          color="primary"
          density="compact"
          :disabled="component.disabled"
          :hide-details="component.hideDetails"
          placeholder="YYYY-MM-DD"
          :readonly="component.readonly"
          :required="component.required"
          rounded="lg"
          :rules="component.required ? rules : []"
          variant="outlined"
          @input="inputDate = $handleInput('date', $event.target.value)"
          @keyup.enter="setDate"
        >
          <template #append-inner>
            <icon-set class="pt-2" icon="calendar_20" />
          </template>
          <template #clear>
            <icon-set class="pt-2" icon="delete_20" @click="onClearData" />
          </template>
          <template #label>
            <span>
              {{ component.label }}
              <span v-if="component.required" class="font-weight-bold text-error"> *</span>
            </span>
          </template>
          <template #message>
            <div v-if="component.required" class="text-right" style="margin: -4px -15px 4px 0">
              <span class="text-error text-body-4">! 필수 항목입니다.</span>
            </div>
          </template>
        </v-text-field>
      </template>
      <v-card rounded="content" width="263">
        <vue-date-picker
          v-model="date"
          class="date-picker primary text-body-3"
          auto-apply
          format="yyyy-MM-dd"
          :enable-time-picker="false"
          inline
          locale="ko"
          :markers="holydays"
          timezone="Asia/Tokyo"
          week-start="0"
          year-first
          @update:model-value="setInputDate"
          @update-month-year="handleMonthYear"
        >
          <template #arrow-left>
            <icon-set class="pt-1" icon="arrow_left_20" />
          </template>
          <template #arrow-right>
            <icon-set class="pt-1" icon="arrow_right_20" />
          </template>
          <template #calendar-header="{ index, day }">
            <div :class="index === 0 ? 'text-error' : index === 6 ? 'text-success' : ''">{{ day }}</div>
          </template>
          <template #marker>
            <span class="custom-marker"></span>
          </template>
          <template #year="{ value }">
            {{ `${value}년` }}
          </template>
        </vue-date-picker>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import * as COMMON_API from '@/api/common'
import dayjs from 'dayjs'

const emits = defineEmits(['set-date'])
const props = defineProps({ component: Object, form: Object })

const date = ref()
const inputDate = ref(null)
const baseDate = ref(null)
const menu = ref(false)
const rules = [(v) => !!v || '필수 항목입니다.']
const dateFormat = ref('YYYY-MM-DD')
const holydays = ref([])
const searchForm = ref({ searchYear: dayjs().format('YYYY'), size: 100 })

watch(
  () => props.form,
  (value) => {
    if (value[props.component.id]) {
      dateFormat.value = props.component.dateFormat !== undefined ? props.component.dateFormat : 'YYYY-MM-DD'
      inputDate.value = dayjs(value[props.component.id]).format(dateFormat.value)
      baseDate.value = dayjs(value[props.component.id]).format('YYYY-MM-DD')
    }
  },
  { deep: true, immediate: true }
)
watch(
  () => inputDate.value,
  (value) => {
    dateFormat.value = props.component.dateFormat !== undefined ? props.component.dateFormat : 'YYYY-MM-DD'
    if (value && value.length > 9) {
      date.value = dayjs(value)
      baseDate.value = dayjs(date.value).format('YYYY-MM-DD')
      getPinesHolydays(dayjs(date.value).format('YYYY'), true)
      emits('set-date', baseDate.value, props.component)
    } else {
      date.value = dayjs().$d
      emits('set-date', '', props.component)
    }
  }
)

onMounted(async () => {
  await getPinesHolydays(dayjs().format('YYYY'), false)
})

const setInputDate = () => {
  inputDate.value = dayjs(date.value).format(dateFormat.value)
  baseDate.value = dayjs(date.value).format('YYYY-MM-DD')
  emits('set-date', inputDate.value, props.component)
  menu.value = false
}
const setDate = () => {
  if (inputDate.value && inputDate.value.length > 9) {
    date.value = dayjs(inputDate.value).$d
    inputDate.value = dayjs(date.value).format(dateFormat.value)
    baseDate.value = dayjs(date.value).format('YYYY-MM-DD')
    emits('set-date', baseDate.value, props.component)
  } else menu.value = true
}
const onClearData = () => {
  date.value = null
  inputDate.value = null
}
const getPinesHolydays = async (year = null, newData = true) => {
  if (searchForm.value.searchYear === year && newData) return
  searchForm.value = {
    searchYear: year ? year : dayjs().format('YYYY'),
    size: 100,
  }
  holydays.value = []
  COMMON_API.getPinesHolydays(searchForm.value).then((payload) => {
    payload.data.contents.map((element) => {
      if (element.startDate === element.endDate) {
        holydays.value.push({
          date: dayjs(element.startDate),
          type: 'dot',
          tooltip: [{ text: element.title, color: 'red' }],
        })
      } else {
        const diff = dayjs(element.endDate).diff(dayjs(element.startDate), 'day')
        for (let i = 0; i <= diff; i++) {
          holydays.value.push({
            date: dayjs(element.startDate).add(i, 'day'),
            type: 'dot',
            tooltip: [{ text: element.title, color: 'red' }],
          })
        }
      }
    })
  })
}
const handleMonthYear = (data) => {
  getPinesHolydays(data.year, true)
}
</script>

<style>
.custom-marker {
  position: absolute;
  top: 0;
  right: 0;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #ff0000;
}
</style>
