import { useCookies } from 'vue3-cookies'
import router from '@/router'

const { cookies } = useCookies()

const PINES_COOKIE = 'PINES_SESSION_EXPIRES'
const EXPIRES_TIME = '60min'
const ALERT_MESSAGE = {
  TOKEN: '로그인 정보가 없습니다',
  EXPIRES: '60분이상 사용이 없어 로그아웃 처리됩니다',
}

const getDomain = () => {
  const hostname = location.hostname
  const splitName = hostname.split('.')
  return splitName.length > 2 ? `.${splitName.slice(1).join('.')}` : hostname
}

let getAuthToken1 = cookies.get('auth-token')
let getAuthToken2 = cookies.get('Authorization-v2')

export const getExpiresSession = () => cookies.get(PINES_COOKIE)
export const setExpiresSession = (time = EXPIRES_TIME) =>
  cookies.set(PINES_COOKIE, PINES_COOKIE, time, '/', getDomain())

export const setAuth = (auth) => {
  getAuthToken1 = auth ? auth.headers.authorization : cookies.get('auth-token')
  getAuthToken2 = auth ? auth.headers['authorization-v2'] : cookies.get('Authorization-v2')
}

router.beforeEach((to, from, next) => {
  const flag = getAuthToken1
  const loginPage = ['/login']
  const authRequire = !loginPage.includes(to.path)

  if (to.matched.some((record) => record.meta.requireAuth) && !flag) {
    return authRequire ? next('/login') : next(to.path)
  }
  next()
})

const deleteAuth = () => {
  const domain = getDomain()
  cookies.remove('auth-token', '/', domain)
  cookies.remove('Authorization-v2', '/', domain)
  cookies.remove(PINES_COOKIE, '/', domain)
}

export const emptyAuth = (message) => {
  if (message) alert(ALERT_MESSAGE[message])
  deleteAuth()
  router.go()
}

export default {
  getDomain,
  getAuthToken1,
  getAuthToken2,
  getExpiresSession,
  setExpiresSession,
}
