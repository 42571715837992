<template>
  <v-menu v-model="menu" :close-on-content-click="false" location="bottom center">
    <template #activator="{ props }">
      <v-text-field
        v-model="contractConceptLabel"
        v-bind="props"
        class="mx-1 mb-1 text-font-color"
        bg-color="#fff"
        base-color="#777"
        color="primary"
        density="compact"
        :hide-details="component.hideDetails"
        label="계약컨셉"
        rounded="lg"
        variant="outlined"
      />
    </template>
    <v-card rounded="content" max-width="810px">
      <div class="d-flex flex-wrap">
        <v-radio-group
          v-for="(group, groupIndex) in contractConceptGroups"
          :key="groupIndex"
          v-model="inputForm[component.id]"
          style="width: 160px"
          color="primary"
          density="compact"
          hide-details
        >
          <v-radio v-for="concept in group" :key="concept.value" :label="concept.label" :value="concept.value" />
        </v-radio-group>
      </div>
    </v-card>
  </v-menu>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue'
import * as COMMON from '@/api/common'

const props = defineProps({ component: Object, form: Object })

const menu = ref(false)
const contractConceptLabel = ref('')
const contractConcepts = ref([])
const inputForm = ref(props.form)

const contractConceptGroups = computed(() => {
  const groupSize = Math.ceil(contractConcepts.value.length / 5)
  return Array.from({ length: 5 }, (_, i) => contractConcepts.value.slice(i * groupSize, (i + 1) * groupSize)).filter(
    (group) => group.length > 0
  )
})

watchEffect(() => {
  const selectedValue = inputForm.value[props.component.id]
  if (selectedValue && contractConcepts.value.length > 0) {
    const selected = contractConcepts.value.find((element) => element.value === selectedValue)
    contractConceptLabel.value = selected?.label ?? ''
  }
})

watchEffect(() => {
  inputForm.value = props.form
})

const getContractConcepts = async () => {
  try {
    const { data } = await COMMON.getPinesCommonCode('ContractConcept')
    contractConcepts.value = data.data
      .filter((element) => element.useYn === 'Y')
      .map((element) => ({
        label: element.codeName,
        value: element.codeId,
        codeOrder: element.codeOrder,
      }))
      .sort((a, b) => a.codeOrder - b.codeOrder)
  } catch (error) {
    console.error('계약 컨셉 로딩 실패:', error)
  }
}

getContractConcepts()
</script>
