<template>
  <v-alert
    v-model="alertVisible"
    class="text-body-1"
    color="primary"
    density="comfortable"
    location="center center"
    position="absolute"
    prominent
    :text="message"
  />
</template>

<script setup>
import { ref, watch } from 'vue'

const emit = defineEmits(['update:visible'])

const props = defineProps({ visible: Boolean, message: String, timeout: { type: Number, default: 3000 } })

const alertVisible = ref(false)

const onClose = () => {
  emit('update:visible', false)
}
watch(
  () => props.visible,
  (newVal) => {
    alertVisible.value = newVal
    setTimeout(() => {
      onClose()
    }, props.timeout)
  }
)
</script>
