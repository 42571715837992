import config from '@/config'
import { createStore } from 'vuex'

export default createStore({
  state: () => ({
    Sidebar_drawer: config.sidebar_drawer,
    Mini_sidebar: config.mini_sidebar,
    Menu_histories: config.menu_histories,
    Selected_history: config.selected_history,
    loader: config.loader,
    large_size: false,
    business_code: config.businessCode,
    snackbar: config.snackbar,
  }),
  getters: {
    getAuthRoles(state) {
      return state.auth_roles
    },
    getUser(state) {
      return state.user
    },
    getDrivingType(state) {
      return state.driving_type
    },
    getDbType(state) {
      return state.db_type
    },
    getCustomerType(state) {
      return state.customer_type
    },
    getCustomerSearchResultType(state) {
      return state.customer_search_result_type
    },
    getCustomerPathType(state) {
      return state.customer_path_type
    },
    getCustomerJobType(state) {
      return state.customer_job_type
    },
    getConsultationType(state) {
      return state.consultation_type
    },
    getConsultationResultType(state) {
      return state.consultation_result_type
    },
    getConsultationPathType(state) {
      return state.consultation_path_type
    },
    getBusinessIndustryFirstType(state) {
      return state.business_industry_first_type
    },
    getBusinessClassificationType(state) {
      return state.business_classification_type
    },
    getAllocationChannelType(state) {
      return state.allocation_channel_type
    },
    getAgreementType(state) {
      return state.agreement_type
    },
    getAgreementStatusType(state) {
      return state.agreement_status_type
    },
    getChangeApprovalType(state) {
      return state.change_approval_type
    },
    getSearchType(state) {
      return state.search_type
    },
    getProductGroupsType(state) {
      return state.product_groups_type
    },
    getCustomerResultType(state) {
      return state.customer_result_type
    },
  },
  mutations: {
    setUser(state, value) {
      state.user = value
    },
    setAuthRoles(state, value) {
      state.auth_roles = value
    },
    setDrivingType(state, value) {
      state.driving_type = value
    },
    setDbType(state, value) {
      state.db_type = value
    },
    setCustomerType(state, value) {
      state.customer_type = value
    },
    setCustomerSearchResultType(state, value) {
      state.customer_search_result_type = value
    },
    setCustomerPathType(state, value) {
      state.customer_path_type = value
    },
    setCustomerJobType(state, value) {
      state.customer_job_type = value
    },
    setConsultationType(state, value) {
      state.consultation_type = value
    },
    setConsultationResultType(state, value) {
      state.consultation_result_type = value
    },
    setConsultationPathType(state, value) {
      state.consultation_path_type = value
    },
    setBusinessIndustryFirstType(state, value) {
      state.business_industry_first_type = value
    },
    setBusinessClassificationType(state, value) {
      state.business_classification_type = value
    },
    setAllocationChannelType(state, value) {
      state.allocation_channel_type = value
    },
    setAgreementType(state, value) {
      state.agreement_type = value
    },
    setAgreementStatusType(state, value) {
      state.agreement_status_type = value
    },
    setChangeApprovalType(state, value) {
      state.change_approval_type = value
    },
    setSearchType(state, value) {
      state.search_type = value
    },
    setProductGroupsType(state, value) {
      state.product_groups_type = value
    },
    setCustomerResult(state, value) {
      state.customer_result_type = value
    },
    SET_MINI_SIDEBAR(state) {
      state.Mini_sidebar = !state.Mini_sidebar
    },
    SET_MENU_HISTORIES(state, value) {
      state.Menu_histories = value
    },
    REMOVE_MENU_HISTORIES(state, value) {
      let menus = JSON.parse(localStorage.getItem('customer-integration-menu-histories'))
      menus.splice(value, 1)
      state.Menu_histories = JSON.stringify(menus)
      localStorage.setItem('customer-integration-menu-histories', JSON.stringify(menus))
    },
    SET_SELECTED_HISTORY(state, value) {
      state.Selected_history = value
      localStorage.setItem('selectedHistory', JSON.stringify(value))
    },
    SHOW_LOADER(state) {
      state.loader = true
    },
    HIDE_LOADER(state) {
      state.loader = false
    },
    SET_LARGE_SIZE(state, value) {
      state.large_size = value
    },
    SET_SNACKBAR(state, payload) {
      state.snackbar.visible = payload.visible
      state.snackbar.message = payload.message || ''
    },
  },
  actions: {
    setMiniSidebar(context) {
      context.commit('SET_MINI_SIDEBAR')
    },
    setMenuHistories(context, payload) {
      context.commit('SET_MENU_HISTORIES', payload)
    },
    removeMenuHistories(context, payload) {
      context.commit('REMOVE_MENU_HISTORIES', payload)
    },
    setSelectedHistory(context, payload) {
      context.commit('SET_SELECTED_HISTORY', payload)
    },
    showLoader(context) {
      context.commit('SHOW_LOADER')
    },
    showSnackbar({ commit }, { message }) {
      commit('SET_SNACKBAR', { visible: true, message })
      setTimeout(() => {
        commit('SET_SNACKBAR', { visible: false })
      }, 2000)
    },
    hideLoader(context) {
      context.commit('HIDE_LOADER')
    },
    setLargeSize(content, payload) {
      content.commit('SET_LARGE_SIZE', payload)
    },
  },
  modules: {},
})
