import { GET } from '@/plugins/axios'
import store from '@/store'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()

const baseUrl = `${process.env.VUE_APP_CUSTOMER_SERVICE}/type`

const ENUM_TYPES = [
  { path: 'driving', mutation: 'setDrivingType' },
  { path: 'db-type', mutation: 'setDbType' },
  { path: 'customer', mutation: 'setCustomerType' },
  { path: 'customer-search-result', mutation: 'setCustomerSearchResultType' },
  { path: 'customer-path', mutation: 'setCustomerPathType' },
  { path: 'customer-job', mutation: 'setCustomerJobType' },
  { path: 'consultation', mutation: 'setConsultationType' },
  { path: 'consultation-result', mutation: 'setConsultationResultType' },
  { path: 'consultation-path', mutation: 'setConsultationPathType' },
  { path: 'business-industry-first', mutation: 'setBusinessIndustryFirstType' },
  { path: 'business-classification', mutation: 'setBusinessClassificationType' },
  { path: 'allocation-channel', mutation: 'setAllocationChannelType' },
  { path: 'agreement', mutation: 'setAgreementType' },
  { path: 'agreement-status', mutation: 'setAgreementStatusType' },
  { path: 'customer-change-approval-type', mutation: 'setChangeApprovalType' },
  { path: 'search-type', mutation: 'setSearchType' },
  { path: 'product-groups', mutation: 'setProductGroupsType' },
  { path: 'customer-result', mutation: 'setCustomerResult' },
]

export const getEnumType = async () => {
  try {
    if (!cookies.get('auth-token')) return

    const requests = ENUM_TYPES.map(({ path, mutation }) =>
      GET(`${baseUrl}/${path}`)
        .then((response) => {
          if (response?.data) {
            store.commit(mutation, response.data)
          }
        })
        .catch((error) => {
          console.error(`Error fetching ${path}:`, error)
        })
    )

    await Promise.all(requests)
  } catch (error) {
    console.error('Error in getEnumType:', error)
  }
}
