<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <v-menu v-model="menu" :close-on-content-click="false" location="bottom">
    <template #activator="{ props }">
      <v-text-field
        v-model="selectedDepartmentsText"
        v-bind="props"
        class="mx-1 mb-1 text-font-color"
        base-color="#777777"
        bg-color="#fff"
        color="primary"
        density="compact"
        :hide-details="component.hideDetails"
        label="부서검색"
        readonly
        rounded="lg"
        variant="outlined"
      >
        <v-tooltip
          v-if="selectedDepartmentsText"
          activator="parent"
          location="bottom"
          :text="selectedDepartmentsText"
        />
      </v-text-field>
    </template>
    <v-card class="mx-auto text-font-color" max-height="500" min-width="300">
      <v-list
        v-model:selected="selection"
        color="primary"
        density="compact"
        item-title="deptName"
        item-value="deptId"
        select-strategy="independent"
      >
        <template v-for="(item, index) in departments" :key="index">
          <tree-collapse
            v-if="item.children"
            :item="item"
            :level="0"
            :selection="selection"
            :selection-departments="selectionDepartments"
          />
          <tree-item v-else :item="item" :selection="selection" :selection-departments="selectionDepartments" />
        </template>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import * as SETTINGS from '@/api/assist-api/settings'
import TreeCollapse from './tree-collapse/TreeCollapse.vue'
import TreeItem from './tree-item/TreeItem.vue'

const props = defineProps({
  activeYn: String,
  maxLevel: { type: Number, default: 3 },
  searchType: String,
  defaultValue: { type: Array, default: () => [] },
  component: Object,
})

const emits = defineEmits(['selection'])

const menu = ref(false)
const departments = ref([])
const selection = ref([])
const selectionDepartments = ref([])

const selectedDepartmentsText = computed(() => selectionDepartments.value.map((element) => element.deptName).join(','))

const fetchDepartments = async () => {
  try {
    const params = {
      activeYn: props.activeYn,
      maxDeptLevel: props.maxLevel,
      searchType: props.searchType,
      sort: 'deptOrder,asc',
    }

    const response = await SETTINGS.getSettingsDepartmentsTree(params)
    departments.value = processDepartmentTree(response.data.contents)
  } catch (error) {
    console.error('부서 정보 조회 실패:', error)
  }
}

const processDepartmentTree = (array = []) => {
  const result = [...array]
  result.forEach((element) => {
    if (element.children?.length > 0) {
      processDepartmentTree(element.children)
    }
  })
  return result
}

watch(
  () => selection.value,
  (newValue) => {
    emits('selection', newValue, props.component)
  },
  { deep: true }
)

onMounted(async () => {
  await fetchDepartments()
  selection.value = props.defaultValue
})
</script>
