import * as ICON from './icon'

export const setParentSidebarIcon = (
  iconName = 'left_menu1',
  selectedFlag = false,
  color = '#2D2D2D',
  subColor = 'black'
) => {
  let icon = ICON.icon(color, subColor)
  return selectedFlag === true ? icon[iconName].on : icon[iconName].off
}

export const setIcon = (iconName, color = '#2D2D2D', subColor = 'black', opacity = 0.2) => {
  let icon = ICON.icon(color, subColor, opacity)
  return icon[iconName]
}
