<template>
  <div class="register-picker">
    <v-menu v-model="menu" :close-on-content-click="false" location="bottom right">
      <template #activator="{ props }">
        <div class="d-flex">
          <v-text-field
            :id="`${component.id}_first`"
            v-model="firstPart"
            v-bind="props"
            class="mx-1 mb-1 text-font-color"
            style="flex: 2"
            autocomplete="off"
            bg-color="#ffffff"
            clearable
            color="primary"
            density="compact"
            :disabled="component.disabled.birthday"
            :hide-details="component.hideDetails"
            maxlength="6"
            placeholder="YYMMDD"
            :required="component.required"
            rounded="lg"
            :rules="component.required ? firstRule : []"
            variant="outlined"
            @input="onInputFirstPart"
          >
            <template #append-inner>
              <icon-set class="pt-2" icon="calendar_20" />
            </template>
            <template #clear>
              <icon-set class="pt-2" icon="delete_20" @click="onClearData" />
            </template>
            <template #label>
              <span>
                {{ component.label }}
                <span v-if="component.required" class="font-weight-bold text-error"> *</span>
              </span>
            </template>
            <template #message>
              <div v-if="component.required" class="text-right" style="margin: -4px -15px 4px 0">
                <span class="text-error text-body-4">! 필수 항목입니다.</span>
              </div>
            </template>
          </v-text-field>
          <span class="pt-2" style="color: #777">-</span>
          <v-text-field
            :id="`${component.id}_second`"
            v-model="secondPart"
            type="number"
            class="mx-1 mb-1 text-font-color"
            style="flex: 2"
            autocomplete="off"
            bg-color="#ffffff"
            color="primary"
            density="compact"
            :disabled="component.disabled.sex"
            :hide-details="component.hideDetails"
            maxlength="1"
            :required="component.required"
            rounded="lg"
            :rules="component.required ? secondRule : []"
            suffix="●●●●●●"
            variant="outlined"
            @input="onInputSecondPart"
          >
            <template #label>
              <span>
                주민번호 뒷자리 첫번째
                <span v-if="component.required" class="font-weight-bold text-error"> *</span>
              </span>
            </template>
          </v-text-field>
        </div>
      </template>
      <v-card rounded="content" width="263">
        <vue-date-picker
          v-model="date"
          class="register-picker primary"
          auto-apply
          format="yyyy-MM-dd"
          :enable-time-picker="false"
          inline
          locale="ko"
          timezone="Asia/Tokyo"
          year-first
          @update:model-value="setInputDate"
        >
          <template #arrow-left>
            <icon-set class="pt-1" icon="arrow_left_20" />
          </template>
          <template #arrow-right>
            <icon-set class="pt-1" icon="arrow_right_20" />
          </template>
          <template #year="{ value }">
            {{ `${value}년` }}
          </template>
        </vue-date-picker>
      </v-card>
    </v-menu>
    <alert-view v-model:visible="alert.visible" :message="alert.message" />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import dayjs from 'dayjs'

// Props
const props = defineProps({ component: Object, form: Object })

// Emit
const emit = defineEmits(['set-register-number'])

// Data
const date = ref(null)
const menu = ref(false)
const firstPart = ref('')
const secondPart = ref('')
const alert = ref({ visible: false, message: '' })
const GENDER_VALIDATION = {
  before2000: ['1', '2', '5', '6'],
  after2000: ['3', '4', '7', '8'],
}

// Rules
const firstRule = [(v) => !!v || '필수 항목입니다.']
const secondRule = [(v) => !!v || '']

// Methods
const emitRegisterNumber = () => {
  emit('set-register-number', [firstPart.value, secondPart.value], props.component)
}

const onInputFirstPart = (event) => {
  const value = event.target?.value || ''
  firstPart.value = value.replace(/\D/g, '').slice(0, 6)
  emitRegisterNumber()
}

const getYearInfo = (firstPart) => {
  const yearPrefix = parseInt(firstPart.slice(0, 2), 10) > 50 ? '19' : '20'
  return {
    prefix: yearPrefix,
    fullYear: parseInt(`${yearPrefix}${firstPart.slice(0, 2)}`, 10),
  }
}

const validateGenderDigit = (digit, birthYear) => {
  const validDigits = birthYear < 2000 ? GENDER_VALIDATION.before2000 : GENDER_VALIDATION.after2000

  return validDigits.includes(digit)
}

const showGenderError = () => {
  alert.value = {
    visible: true,
    message: '성별 확인 바랍니다',
  }
  secondPart.value = ''
}

const onInputSecondPart = (event) => {
  const oneDigitValue = (event.target?.value || '').replace(/\D/g, '').slice(0, 1)
  secondPart.value = oneDigitValue

  if (oneDigitValue) {
    const { fullYear } = getYearInfo(firstPart.value)

    if (!validateGenderDigit(oneDigitValue, fullYear)) {
      showGenderError()
    }
  }

  emitRegisterNumber()
}
const setInputDate = (selectedDate) => {
  if (!selectedDate) return

  firstPart.value = dayjs(selectedDate).format('YYMMDD')
  emitRegisterNumber()
  menu.value = false
}
const onClearData = () => {
  date.value = null
  firstPart.value = ''
  secondPart.value = ''
  emitRegisterNumber()
}

// Watch
watch(
  () => props.form,
  (value) => {
    if (value && value[props.component.id]) {
      firstPart.value = value[props.component.id]
      date.value = dayjs(value[props.component.id])
      if (value.birthday2) {
        secondPart.value = value.birthday2
      }
    } else {
      firstPart.value = ''
      secondPart.value = ''
      date.value = null
    }
  },
  { deep: true, immediate: true }
)
watch(firstPart, (newDate) => {
  if (newDate && newDate.length === 6) {
    const yearPrefix = parseInt(newDate.slice(0, 2), 10) > 50 ? '19' : '20'
    const formattedDate = dayjs(`${yearPrefix}${newDate}`, 'YYYYMMDD')
    if (formattedDate.isValid()) date.value = formattedDate.toDate()
  }
})
</script>
