export const Roles = {
  STAFF: 'ROLE_STAFF',
  FC: 'ROLE_FC',
}

export const RouteName = {
  CORP_INTEGRATION: 'CorpIntegrationSearch',
  CORP_INTEGRATION_VIEW: 'CorpIntegrationSearchView',
  MANAGING_CUSTOMER: 'ManagingCustomerRegistration',
  INDIVIDUAL_CUSTOMER: 'IndividualCustomerRegistration',
  CORPORATE_CUSTOMER: 'CorporateCustomerRegistration',
  AGREE_LIST: 'AgreeList',
  SUBSCRIPTION: 'SubscriptionManagement',
  NEW_CONTRACT: 'NewContractRequestList',
  CUSTOMER_INFO: 'CustomerInformationManagement',
  CUSTOMER_INFO_MODIFY: 'CustomerInfoModifyRequest',
  CUSTOMER_LIST: 'CustomerList',
  CUSTOMER_LIST_VIEW: 'CustomerListView',
  RENEW_APPROVAL: 'RenewApprovalListView',
  CUSTOMER_SEGMENT: 'CustomerSegmentation',
  MANAGING_OUTPUT: 'ManagingOutputCriteria',
  NOTICE: 'Notice',
  NOTICE_VIEW: 'NoticeView',
}

const DEFAULT_META = {
  requireAuth: true,
  roles: [Roles.STAFF, Roles.FC],
}

const views = {
  layout: () => import('@/layouts/DefaultLayout.vue'),
  corpIntegration: {
    layout: () => import('@/views/corp-integration-search/CorpIntegrationSearchLayout.vue'),
    view: () => import('@/views/corp-integration-search/components/CorpIntegrationSearchView.vue'),
  },
  managingCustomer: {
    layout: () => import('@/views/managing-customer-registration/ManagingCustomerRegistrationLayout.vue'),
    individual: () => import('@/views/managing-customer-registration/components/IndividualCustomerView.vue'),
    corporate: () => import('@/views/managing-customer-registration/components/CorporateCustomerView.vue'),
    agree: () => import('@/views/managing-customer-registration/components/AgreeListView.vue'),
  },
  subscription: {
    layout: () => import('@/views/subscription-management/SubscriptionManagementLayout.vue'),
    contract: () => import('@/views/subscription-management/components/NewContractRequestListView.vue'),
  },
  customerInfo: {
    layout: () => import('@/views/customer-information-management/CustomerInformationManagementLayout.vue'),
    modify: () => import('@/views/customer-information-management/components/CustomerInfoModifyRequestView.vue'),
  },
  customerList: {
    layout: () => import('@/views/customer-list/CustomerListLayout.vue'),
    list: () => import('@/views/customer-list/components/CustomerListView.vue'),
    renew: () => import('@/views/customer-list/components/RenewApprovalListView.vue'),
  },
  customerSegment: {
    layout: () => import('@/views/customer-segmentation/CustomerSegmentationLayout.vue'),
    criteria: () => import('@/views/customer-segmentation/components/ManagingOutputCriteriaView.vue'),
  },
  notice: {
    layout: () => import('@/views/notice/NoticeLayout.vue'),
    list: () => import('@/views/notice/components/NoticeView.vue'),
  },
}

const mainRoutes = {
  path: '/',
  component: views.layout,
  meta: DEFAULT_META,
  redirect: '/corp-integration-search',
  children: [
    {
      name: RouteName.CORP_INTEGRATION,
      path: '/corp-integration-search',
      component: views.corpIntegration.layout,
      meta: { ...DEFAULT_META, title: '고객통합조회' },
      children: [
        {
          name: RouteName.CORP_INTEGRATION_VIEW,
          path: '',
          component: views.corpIntegration.view,
          meta: { ...DEFAULT_META, title: '고객통합조회' },
        },
      ],
    },
    {
      name: RouteName.MANAGING_CUSTOMER,
      path: '/managing-customer-registration',
      component: views.managingCustomer.layout,
      meta: { ...DEFAULT_META, title: '고객등록관리' },
      children: [
        {
          name: RouteName.INDIVIDUAL_CUSTOMER,
          path: '/individual-customer-registration',
          component: views.managingCustomer.individual,
          meta: { ...DEFAULT_META, title: '개인고객등록' },
        },
        {
          name: RouteName.CORPORATE_CUSTOMER,
          path: '/corporate-customer-registration',
          component: views.managingCustomer.corporate,
          meta: { ...DEFAULT_META, title: '법인고객등록' },
        },
        {
          name: RouteName.AGREE_LIST,
          path: '/agree-list',
          component: views.managingCustomer.agree,
          meta: { ...DEFAULT_META, title: '동의리스트' },
        },
      ],
    },
    {
      name: RouteName.SUBSCRIPTION,
      path: '/subscription-management',
      component: views.subscription.layout,
      meta: { ...DEFAULT_META, title: '청약관리' },
      children: [
        {
          name: RouteName.NEW_CONTRACT,
          path: '/new-contract-request-list',
          component: views.subscription.contract,
          meta: { ...DEFAULT_META, title: '신계약입력 요청 현황' },
        },
      ],
    },
    {
      name: RouteName.CUSTOMER_INFO,
      path: '/customer-information-management',
      component: views.customerInfo.layout,
      meta: { ...DEFAULT_META, title: '고객정보관리' },
      children: [
        {
          name: RouteName.CUSTOMER_INFO_MODIFY,
          path: '/customer-info-modify-request',
          component: views.customerInfo.modify,
          meta: { ...DEFAULT_META, title: '고객정보관리' },
        },
      ],
    },
    {
      name: RouteName.CUSTOMER_LIST,
      path: '/my-customer-list',
      component: views.customerList.layout,
      meta: { ...DEFAULT_META, title: '나의 고객리스트' },
      children: [
        {
          name: RouteName.CUSTOMER_LIST_VIEW,
          path: '/customer-list',
          component: views.customerList.list,
          meta: { ...DEFAULT_META, title: '나의 고객리스트' },
        },
        {
          name: RouteName.RENEW_APPROVAL,
          path: '/renew-approval-list',
          component: views.customerList.renew,
          meta: { ...DEFAULT_META, title: '재동의리스트' },
        },
      ],
    },
    {
      name: RouteName.CUSTOMER_SEGMENT,
      path: '/customer-segmentation',
      component: views.customerSegment.layout,
      meta: { ...DEFAULT_META, title: '고객 Segmentation' },
      children: [
        {
          name: RouteName.MANAGING_OUTPUT,
          path: '/managing-output-criteria',
          component: views.customerSegment.criteria,
          meta: { ...DEFAULT_META, title: '영업대상 고객분류' },
        },
      ],
    },
    {
      name: RouteName.NOTICE,
      path: '/notice',
      component: views.notice.layout,
      meta: { ...DEFAULT_META, title: '공지사항' },
      children: [
        {
          name: RouteName.NOTICE_VIEW,
          path: '/notice-list',
          component: views.notice.list,
          meta: { ...DEFAULT_META, title: '공지사항' },
        },
      ],
    },
  ],
}

export default mainRoutes
