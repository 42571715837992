<template>
  <div class="date-range-picker">
    <v-menu v-model="menu" :close-on-content-click="false" location="bottom center">
      <template #activator="{ props }">
        <v-text-field
          :id="component.id"
          v-model="displayDate"
          v-bind="props"
          class="mx-1 mb-1 text-font-color"
          autocomplete="off"
          base-color="#777777"
          bg-color="#ffffff"
          color="primary"
          density="compact"
          :disabled="component.disabled"
          :hide-details="component.hideDetails"
          placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
          :required="component.required"
          rounded="lg"
          :rules="component.required ? rules : []"
          variant="outlined"
          @click="menu = true"
          @input="displayDate = $handleInput('dateRange', $event.target.value)"
        >
          <template #append-inner>
            <icon-set class="pt-2" icon="calendar_20" />
          </template>
          <template #label>
            <span>
              {{ component.label }}
              <span v-if="component.required" class="font-weight-bold text-error"> *</span>
            </span>
          </template>
          <template #message>
            <div v-if="component.required" class="text-right" style="margin: -4px -15px 4px 0">
              <span class="text-error text-body-4">! 필수 항목입니다.</span>
            </div>
          </template>
        </v-text-field>
      </template>
      <v-card rounded="content" width="323">
        <vue-date-picker
          v-model="dateRange"
          class="date-range-picker primary"
          auto-apply
          :enable-time-picker="false"
          inline
          locale="ko"
          :markers="holydays"
          no-today
          :preset-dates="presetDates"
          range
          timezone="Asia/Tokyo"
          week-start="0"
          year-first
          @update:model-value="setDateRange"
          @update-month-year="handleMonthYear"
        >
          <template #arrow-left>
            <icon-set class="pt-1" icon="arrow_left_20" />
          </template>
          <template #arrow-right>
            <icon-set class="pt-1" icon="arrow_right_20" />
          </template>
          <template #calendar-header="{ index, day }">
            <div :class="index === 0 ? 'text-error' : index === 6 ? 'text-success' : ''">{{ day }}</div>
          </template>
          <template #marker>
            <span class="custom-marker"></span>
          </template>
          <template #year="{ value }">
            {{ `${value}년` }}
          </template>
        </vue-date-picker>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import * as COMMON_API from '@/api/common'
import dayjs from 'dayjs'

const emits = defineEmits(['set-range-date'])
const props = defineProps({ component: Object, form: Object })

const dateRange = ref([])
const displayDate = ref('')
const menu = ref(false)
const rules = [(v) => !!v || '필수 항목입니다.']
const sendDateRange = ref([])
const holydays = ref([])
const searchForm = ref({ searchYear: dayjs().format('YYYY'), size: 100 })

const setDateRange = (value) => {
  dateRange.value = value

  const startDate = dayjs(dateRange.value[0]).format('YYYY-MM-DD')
  const endDate = dayjs(dateRange.value[value.length - 1]).format('YYYY-MM-DD')

  if (value.length >= 2) {
    displayDate.value = `${startDate} ~ ${endDate}`
    sendDateRange.value = [startDate, endDate]
  } else if (value.length === 1) {
    const selectedDate = dayjs(dateRange.value[0]).format('YYYY-MM-DD')
    displayDate.value = `${selectedDate} ~ ${selectedDate}`
    sendDateRange.value = [selectedDate, selectedDate]
  } else {
    displayDate.value = ''
    sendDateRange.value = []
  }
  emits('set-range-date', sendDateRange.value, props.component)
}

watch(
  () => props.component.defaultValue,
  (value) => {
    if (value) setDateRange(value)
  },
  { deep: true, immediate: true }
)
watch(
  () => displayDate.value,
  (value) => {
    if (value.length === 0) setDateRange([])
    else if (value.length === 21) setDateRange(value.split('~'))
  }
)
watch(
  () => props.form,
  (value) => {
    if (value[props.component.ranges[0]] || value[props.component.ranges[1]]) {
      dateRange.value = [dayjs(value[props.component.ranges[0]]), dayjs(value[props.component.ranges[1]])]
      displayDate.value = `${dayjs(value[props.component.ranges[0]]).format('YYYY-MM-DD')} ~ ${dayjs(value[props.component.ranges[1]]).format('YYYY-MM-DD')}`
    } else {
      dateRange.value = []
      displayDate.value = ''
    }
  },
  { deep: true, immediate: true }
)

onMounted(async () => {
  await getPinesHolydays(dayjs().format('YYYY'), false)
})

const presetDates = ref([
  { label: '이번달', value: [dayjs().startOf('M'), dayjs().endOf('M')] },
  { label: '1달전', value: [dayjs().subtract(1, 'M').startOf('M'), dayjs().subtract(1, 'M').endOf('M')] },
  { label: '2달전', value: [dayjs().subtract(2, 'M').startOf('M'), dayjs().subtract(2, 'M').endOf('M')] },
])
const getPinesHolydays = async (year = null, newData = true) => {
  if (searchForm.value.searchYear === year && newData) return
  searchForm.value = {
    searchYear: year ? year : dayjs().format('YYYY'),
    size: 100,
  }
  holydays.value = []
  COMMON_API.getPinesHolydays(searchForm.value).then((payload) => {
    payload.data.contents.map((element) => {
      if (element.startDate === element.endDate) {
        holydays.value.push({
          date: dayjs(element.startDate),
          type: 'dot',
          tooltip: [{ text: element.title, color: 'red' }],
        })
      } else {
        const diff = dayjs(element.endDate).diff(dayjs(element.startDate), 'day')
        for (let i = 0; i <= diff; i++) {
          holydays.value.push({
            date: dayjs(element.startDate).add(i, 'day'),
            type: 'dot',
            tooltip: [{ text: element.title, color: 'red' }],
          })
        }
      }
    })
  })
}
const handleMonthYear = (data) => {
  getPinesHolydays(data.year, true)
}
</script>

<style>
.custom-marker {
  position: absolute;
  top: 0;
  right: 0;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #ff0000;
}
</style>
