const authRoutes = {
  path: '/auth',
  component: () => import('@/layouts/blank/BlankLayout.vue'),
  meta: {
    requireAuth: false,
  },
  children: [
    {
      name: 'Login',
      path: '/login',
      component: () => import('@/views/auth/LoginView.vue'),
    },
    // {
    //   name: 'ErrorPage',
    //   path: '/error',
    //   component: () => import('404페이지'),
    // },
  ],
}

export default authRoutes
