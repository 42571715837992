<template>
  <v-row class="mb-3" align="center" no-gutters>
    <v-col>
      <v-icon v-if="toolbarInfo.title" color="primary" icon="mdi-tally-mark-1" size="x-large" />
      <span
        v-if="toolbarInfo.title"
        class="text-title-3 text-font-color mr-3"
        style="vertical-align: middle"
        v-html="toolbarInfo.title"
      />
      <span v-if="toolbarInfo.content" class="text-body-3 text-font-color" v-html="toolbarInfo.content" />
    </v-col>
    <template v-if="buttonCnt > 0">
      <template v-for="(element, index) in toolbarInfo.buttons">
        <span v-if="element.viewer === undefined || element.viewer" :key="index">
          <v-btn
            class="text-title-5 ml-3"
            color="primary"
            :disabled="!element.disabled ? false : true"
            flat
            rounded="lg"
            :text="element.title"
            :width="_state.large_size ? '176px' : '154px'"
            @click="onClick(element.title)"
          >
            <template #append>
              <icon-set class="pt-1" color="#ffffff" :icon="element.icon" />
            </template>
          </v-btn>
        </span>
      </template>
    </template>
  </v-row>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const emits = defineEmits(['event'])
const props = defineProps({ toolbarInfo: Object })
const store = useStore()

const buttonCnt = computed(() => {
  return props.toolbarInfo.buttons !== undefined ? props.toolbarInfo.buttons.length : 0
})
const _state = computed(() => {
  return store.state
})

const onClick = (title) => {
  emits('event', title)
}
</script>
