<template>
  <v-card :class="`${bgColor} pa-5`" flat rounded="content">
    <v-form :id="id">
      <v-row
        v-for="(element, index) in searchComponents"
        :key="index"
        :class="{ 'mb-1': searchComponents.length > 0 }"
        align="center"
        :justify="componentLocation"
        no-gutters
      >
        <v-col v-for="(component, cIndex) in element" :key="`${component.id}=${index}-${cIndex}`" :cols="grid">
          <v-text-field
            v-if="component.type === 'input'"
            :id="component.id"
            v-model="searchForm[component.id]"
            class="mx-1 mb-1 text-font-color"
            autocomplete="off"
            base-color="#777777"
            bg-color="#fff"
            color="primary"
            density="compact"
            hide-details
            :label="component.label"
            :placeholder="component.placeholder"
            rounded="lg"
            variant="outlined"
            @input="searchForm[component.id] = $handleInput(component.formatter, $event.target.value)"
            @keyup.enter="onSearch"
          />
          <v-select
            v-if="component.type === 'select'"
            :id="component.id"
            v-model="searchForm[component.id]"
            class="mx-1 mb-1 text-font-color"
            autocomplete="off"
            base-color="#777777"
            bg-color="#fff"
            color="primary"
            density="compact"
            focused
            hide-details
            :item-title="component.itemTitle ? component.itemTitle : 'title'"
            :item-value="component.itemValue ? component.itemValue : 'value'"
            :items="component.items"
            :label="component.label"
            menu-icon="mdi-chevron-down"
            rounded="lg"
            variant="outlined"
          >
            <template #item="{ props, item }">
              <div v-bind="props" class="cursor-pointer mx-4 py-3 px-2 rounded-lg text-body-3 select-items-custom">
                {{ item.title }}
              </div>
            </template>
          </v-select>
          <v-autocomplete
            v-if="component.type === 'autocomplete'"
            :id="component.id"
            v-model="searchForm[component.id]"
            class="mx-1 mb-1 text-font-color"
            autocomplete="off"
            base-color="#777777"
            bg-color="#fff"
            color="primary"
            density="compact"
            hide-details
            :items="component.items"
            :label="component.label"
            menu-icon="mdi-chevron-down"
            rounded="lg"
            variant="outlined"
          />
          <component
            v-else-if="isDatePickerType(component.type)"
            v-show="componentVisible"
            :is="getDatePickerComponent(component.type)"
            :form="searchForm"
            :component="component"
            @update:form="handleFormUpdate"
            @set-date="setDate"
            @set-range-date="setRangeDate"
          />
          <department-tree
            v-if="component.type === 'departmentTree'"
            :id="component.id"
            :active-yn="component.activeYn ? component.activeYn : ''"
            :component="component"
            :default-value="component.defaultValue ? component.defaultValue : []"
            :max-level="component.maxLevel ? component.maxLevel : 3"
            :search-type="component.searchType ? component.searchType : ''"
            @selection="setSelectionDepartment"
          />
          <slot v-if="component.type === 'custom'" :item="component" />
        </v-col>
        <v-divider
          v-if="searchComponents.length - 1 === index"
          :style="`height: ${_state.large_size ? '42px' : '38px'}; margin: 0 10px`"
          vertical
        />
        <v-col
          v-if="searchComponents.length - 1 === index"
          :class="{ 'd-flex align-center': tooltip }"
          :cols="_btnCols"
        >
          <v-btn
            v-if="searchBtnVisible"
            class="mx-1 mb-1 text-title-5"
            color="grey-darken-3"
            :min-width="_state.large_size ? '90px' : '78.75px'"
            rounded="lg"
            text="검색"
            variant="flat"
            @click="onSearch"
          >
            <template #append>
              <icon-set v-if="innerWidth > 1650" class="pt-1" color="#FFFFFF" icon="search_20" />
            </template>
          </v-btn>
          <v-btn
            v-if="initializeBtnVisible"
            class="mx-1 mb-1 text-title-5"
            color="#666666"
            :min-width="_state.large_size ? '90px' : '78.75px'"
            rounded="lg"
            text="초기화"
            variant="flat"
            @click="onInitialize"
          >
            <template #append>
              <icon-set v-if="innerWidth > 1650" class="pt-1" color="#FFFFFF" icon="refresh_20" />
            </template>
          </v-btn>
          <template v-if="tooltip">
            <v-tooltip
              style="white-space: pre-line"
              close-on-content-click
              content-class="pa-5"
              open-on-click
              :open-on-hover="false"
              :text="tooltip.content"
            >
              <template #activator="{ props }">
                <span v-bind="props" class="cursor-pointer text-body-3 text-primary mx-1">{{ tooltip.title }}</span>
                <icon-set v-bind="props" class="cursor-pointer pt-1" icon="question_primary_20" />
              </template>
            </v-tooltip>
          </template>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import DatePicker from '../date-picker/DatePicker.vue'
import DateRangePicker from '../date-picker/DateRangePicker.vue'
import MonthPicker from '../date-picker/MonthPicker.vue'
import MonthRangePicker from '../date-picker/MonthRangePicker.vue'
import YearPicker from '../date-picker/YearPicker.vue'

const emits = defineEmits(['search', 'initialize'])
const props = defineProps({
  id: { type: String, default: 'defaultSearchForm' },
  propsForm: Object,
  searchComponents: Array,
  grid: { type: Number, default: 2 },
  componentLocation: { type: String, default: 'center' },
  bgColor: { type: String, default: 'bg-grey-lighten-4' },
  tooltip: { type: Object, default: null },
  searchBtnVisible: { type: Boolean, default: true },
  initializeBtnVisible: { type: Boolean, default: true },
})
const store = useStore()

const searchForm = ref({})
const componentVisible = ref(false)
const defaultDateValue = ref(null)
const innerWidth = ref(1920)

const _state = computed(() => {
  return store.state
})
const _btnCols = computed(() => {
  if (props.tooltip || (props.searchComponents.length === 1 && Object.keys(props.searchComponents[0]).length >= 5))
    return ''
  else return 2
})
const updateSize = () => {
  window.onresize = function () {
    innerWidth.value = window.innerWidth
  }
}

onMounted(() => {
  componentVisible.value = true
  innerWidth.value = document.documentElement.scrollWidth
  searchForm.value = props.propsForm
})

watch(
  () => props.propsForm,
  async (value) => {
    searchForm.value = value
  },
  { deep: true }
)
watch(
  () => updateSize(),
  () => {},
  { deep: true, immediate: true }
)

const onSearch = () => {
  emits('search')
}
const onInitialize = () => {
  componentVisible.value = false
  emits('initialize')
  setTimeout(() => {
    componentVisible.value = true
  }, 10)
}
const setDate = (value, component) => {
  searchForm.value[component.id] = value
}
const setRangeDate = (value, component) => {
  defaultDateValue.value = value
  if (component.ranges.length >= 2 && value.length > 0) {
    searchForm.value[component.ranges[0]] = value[0]
    searchForm.value[component.ranges[1]] = value[1]
  } else {
    searchForm.value[component.ranges[0]] = ''
    searchForm.value[component.ranges[1]] = ''
  }
}
const setSelectionDepartment = (value, component) => {
  searchForm.value[component.id] = value.join(',')
}

const datePickerComponents = computed(() => ({
  date: DatePicker,
  dateRange: DateRangePicker,
  month: MonthPicker,
  monthRange: MonthRangePicker,
  year: YearPicker,
}))

const isDatePickerType = (type) => Object.keys(datePickerComponents.value).includes(type)

const getDatePickerComponent = (type) => datePickerComponents.value[type]
const handleFormUpdate = (newForm) => {
  searchForm.value = { ...searchForm.value, ...newForm }
}
</script>

<style>
.select-items-custom:hover {
  background-color: #fff1e3;
}
</style>
