<template>
  <v-select
    :id="component.id"
    v-model="inputForm[component.id]"
    class="mx-1 mb-1 text-font-color"
    autocomplete="off"
    base-color="#777"
    clearable
    color="primary"
    density="compact"
    :disabled="component.disabled"
    :hide-details="component.hideDetails"
    menu-icon="mdi-chevron-down"
    :multiple="component.multiple"
    :item-title="component.itemTitle ? component.itemTitle : 'title'"
    :item-value="component.itemValue ? component.itemValue : 'value'"
    :items="component.items"
    :readonly="component.readonly"
    rounded="lg"
    :rules="component.required ? rules : []"
    :required="component.required"
    variant="outlined"
  >
    <template #clear>
      <icon-set v-if="!component.disabled && !component.readonly" class="pt-2" icon="delete_20" @click="onClearData" />
    </template>
    <template #item="{ props, item }">
      <div v-bind="props" class="cursor-pointer mx-4 py-3 px-2 rounded-lg text-body-3 select-items-custom">
        {{ item.title }}
      </div>
    </template>
    <template #label>
      {{ component.label }}
      <span v-if="component.required" class="font-weight-bold text-error"> *</span>
    </template>
    <template #message>
      <div v-if="component.required" class="text-right" style="margin: -4px -15px 4px 0">
        <span class="text-error text-body-4">! 필수 항목입니다.</span>
      </div>
    </template>
  </v-select>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'

const props = defineProps({ component: Object, form: Object })

const inputForm = ref({})
const rules = [(v) => !!v || '필수 항목입니다.']

onMounted(() => {
  inputForm.value = props.form
})

watch(
  () => props.form,
  (value) => {
    inputForm.value = value
    if (props.component.defaultValue) inputForm.value[props.component.id] = props.component.defaultValue
  },
  { deep: true }
)

const onClearData = () => {
  inputForm.value[props.component.id] = props.component.defaultValue
}
</script>

<style>
.select-items-custom:hover {
  background-color: #fff1e3;
}
</style>
