<template>
  <v-switch
    v-model="inputForm[component.id]"
    class="mx-1"
    color="primary"
    density="compact"
    :label="component.label"
    false-value="N"
    true-value="Y"
    hide-details
    inset
  />
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'

const props = defineProps({ component: Object, form: Object })

const inputForm = ref({})

onMounted(() => {
  inputForm.value = props.form
})

watch(
  () => props.form,
  (value) => {
    inputForm.value = value
  },
  { deep: true }
)
</script>
