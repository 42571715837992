<template>
  <v-pagination
    v-model="currentPage"
    class="align-center d-flex text-body-3"
    active-color="primary"
    :length="totalPages"
    show-first-last-page
    total-visible="4"
    @update:modelValue="onClick"
  >
    <template #first>
      <v-btn :disabled="currentPage === 1" rounded="circle" size="30" variant="text" @click="onFirst">
        <icon-set class="pt-1" icon="front_20" />
      </v-btn>
    </template>
    <template #prev>
      <v-btn :disabled="currentPage === 1" rounded="circle" size="30" variant="text" @click="onPrev">
        <icon-set class="pt-1" icon="arrow_left_20" />
      </v-btn>
    </template>
    <template #item="{ isActive, key, page }">
      <v-btn
        :color="isActive ? 'primary' : ''"
        :disabled="props.totalPages === undefined || page === '...'"
        rounded="circle"
        size="30"
        :text="page"
        :variant="isActive ? 'flat' : 'text'"
        @click="onClickPage(key)"
      />
    </template>
    <template #next>
      <v-btn
        :disabled="!props.totalPages || props.totalPages === undefined || currentPage === props.totalPages"
        rounded="circle"
        size="30"
        variant="text"
        @click="onNext"
      >
        <icon-set class="pt-1" icon="arrow_right_20" />
      </v-btn>
    </template>
    <template #last>
      <v-btn
        :disabled="!props.totalPages || props.totalPages === undefined || currentPage === props.totalPages"
        rounded="circle"
        size="30"
        variant="text"
        @click="onLast"
      >
        <icon-set class="pt-1" icon="back_20" />
      </v-btn>
    </template>
  </v-pagination>
</template>

<script setup>
import { ref, watch } from 'vue'

const emits = defineEmits(['changePage'])
const props = defineProps({ currentPage: Number, totalPages: Number })

const currentPage = ref(1)

watch(
  () => props.currentPage,
  (value) => {
    if (value === 0) currentPage.value = 1
  }
)

const onClick = (value) => {
  emits('changePage', value)
}
const onFirst = () => {
  currentPage.value = 1
  emits('changePage', currentPage.value)
}
const onPrev = () => {
  currentPage.value -= 1
  emits('changePage', currentPage.value)
}
const onNext = () => {
  currentPage.value += 1
  emits('changePage', currentPage.value)
}
const onLast = () => {
  currentPage.value = props.totalPages
  emits('changePage', currentPage.value)
}
const onClickPage = (key) => {
  currentPage.value = key
  emits('changePage', currentPage.value)
}
</script>
